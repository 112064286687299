import React, { ReactElement } from "react";
import Image from "next/image";

export default function SystemBasics(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-16 gap-10  lg:mt-36 mt-16  ">
            <div className="w-[240px] h-[210px] md:w-[400px] md:h-[350px] lg:h-[500px] lg:w-[575px] lg:min-w-[575px] relative mx-auto lg:mx-0  ">
                <Image
                    src={"/pointofsaleprices/assets/images/systemBasicsImg.png"}
                    alt="image"
                    layout="fill"
                />
            </div>
            <div className="max-w-[579px]  mx-auto lg:mx-0 ">
                <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px] text-[#1A7184]">
                    POS System Basics
                </h2>
                <p className="mt-5   lg:text-lg text-base lg:leading-7 font-light">
                    A POS system, short for point-of-sale system, is a
                    comprehensive tool designed to facilitate transaction
                    processing and streamline various business operations. It
                    serves as the primary interface where customers make
                    payments for their purchases. Unlike a traditional cash
                    register, a modern POS system offers a wide range of
                    features and functionalities that align with contemporary
                    standards.
                </p>
                <p className="mt-7 lg:text-lg text-base lg:leading-7 font-light">
                    In addition to serving as a cash register, POS software
                    enables businesses to perform various tasks, such as
                    inventory management, sales tracking, reporting, and more.
                    The cash drawer component is commonly referred to as the POS
                    cash register. With its capabilities, a POS system enhances
                    efficiency and provides businesses with valuable insights to
                    optimize their operations.
                </p>
                <span className="w-[150px] h-[4px] block bg-[#F38830] mt-10"></span>
            </div>
        </section>
    );
}
